<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base" v-if="is_filter">
      <vx-card title="Filter">
        <div class="vx-row mb-3">
          <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-3/4 w-full">
            <browse-company class="w-full" name="company_id" label="Company" v-model="filterData.company_id" @input="changeCompany"></browse-company>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-3/4 w-full">
            <browse-contact class="w-full" :iscustomer="true" name="customer_id" label="Customer" v-model="filterData.customer_id" v-on:onChangeContact="changeCustomer"></browse-contact>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-3/4 w-full">
              <small>Status</small>
              <div class='mt-1'>
                  <input class='mr-2' type='radio' value='true' vs-value='true' name='is_active' v-model="filterData.is_active" @change="changeStatusOption">
                  <span class='mr-6'>Active</span>
                  <input class='mr-2' type='radio' value='false' vs-value='false' name='is_active' v-model="filterData.is_active" @change="changeStatusOption">
                  <span class='mr-6'>Non-Active</span>
                </div>
              </div>
        </div>
        <div class="vx-col w-full flex mt-3">
          <vs-button color="warning" @click="resetFilter()">Reset</vs-button>
        </div>
      </vx-card>
    </div>
    <div class="vx-col w-full mb-base">
      <vx-card title="Contracts">
        <vs-table :sst="true" search @search="handleSearch" :data="data.data" @sort="handleSort" class="mb-3" >
          <template slot="header">
            <vs-button color="success" class='ml-1 mr-1' @click="is_filter = !is_filter">Filter</vs-button>&nbsp;
            <vs-button color="primary" :to="{name: 'contracts-create'}">Add New</vs-button>
          </template>
          <template slot="thead">
            <vs-th sort-key="code">Contract Code</vs-th>
            <vs-th sort-key="name">Contract Name</vs-th>
            <vs-th sort-key="company_name">Company Name</vs-th>
            <vs-th sort-key="customer_name">Customer Name</vs-th>
            <vs-th sort-key="is_active">Status</vs-th>
            <vs-th sort-key="created_at"></vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.code">
                <router-link :to="{name:'contracts-show', params:{id: tr.id}}">{{tr.code}} </router-link>
              </vs-td>
              <vs-td label="Contract Name" :data="tr.name">{{tr.name}}</vs-td>
              <vs-td label="Company Name" :data="tr.company_name">{{tr.company_name}}</vs-td>
              <vs-td label="Customer Name" :data="tr.customer_name">{{tr.customer_name}}</vs-td>
              <vs-td :data="tr.is_active">
                    <vs-chip size="small" v-if="tr.is_active" color="success"><vs-avatar color="success" icon-pack="feather" icon="icon-check"></vs-avatar> Active</vs-chip>
                    <vs-chip size="small" v-if="!tr.is_active"><vs-avatar color="danger" icon-pack="feather" icon="icon-x"></vs-avatar> Inactive</vs-chip>
              </vs-td>
              <vs-td :data="tr.id" width="10">
                <div class="flex">
                  <vs-button size="small" type="line" :to="{name:'contracts-show', params:{id: tr.id}}" icon-pack="feather" icon="icon-folder"></vs-button>
                  <vs-button size="small" type="line" color='success' :to="{name:'contracts-edit', params:{id: tr.id}}" icon-pack="feather" icon="icon-edit"></vs-button>
                  <vs-button size="small" type="line" color='danger' @click="deletes(tr.id)" icon-pack="feather" icon="icon-trash"></vs-button>
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination :total="data.last_page" v-model="currentPage" class="mt-5"></vs-pagination>
      </vx-card>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import BrowseContact from '@browse/Contact.vue'
import BrowseCompany from '@browse/Company.vue'

export default {
  components:{
    BrowseContact,
    BrowseCompany
  },
  data(){
    return {
      filterData : {},
      is_filter : false,
      idDelete: null,
      currentPage: 1,
      search:null,
      sortKey:null,
      sortVal:null
    }
  },
  watch:{
    search(){
      this.currentPage=1
      this.getData()
    },
    currentPage(){
      this.getData()
    }
  },
  computed:{
    ...mapState({
      data: state => state.contracts.rows
    })
  },
  methods:{
    ...mapActions({
      dispatchIndex: 'contracts/index',
      dispatchDestroy: 'contracts/destroy'
    }),
    async getData(){
      let payload = {
        page: this.currentPage,
        search: this.search,
        sortKey: this.sortKey,
        sortVal: this.sortVal
      }
      payload = Object.assign(payload, this.filterData)
      await this.dispatchIndex(payload)
    },
    async confirmDelete(){
      try {
        await this.dispatchDestroy(this.idDelete)
        this.getData()
        this.$vs.notify({
          title: `Success`,
          text: `Your data has been deleted successfully`,
          color: `primary`
        })
      } catch (error) {
        this.$vs.notify({
          title: `Oops!`,
          text: `Looks like something went wrong. please try again later (${error.data.message})`,
          color: `danger`
        })
      }
    },
    deletes(id){
      this.idDelete = id
      this.$vs.dialog({
        type: `confirm`,
        color: `danger`,
        title: `Are you sure ?`,
        text: `Deleted data can no longer be restored`,
        accept: this.confirmDelete
      })
    },
    handleSearch(val){
      this.search = val
    },
    handleSort(key,val){
      this.sortKey = key
      this.sortVal = val
      this.getData()
    },
    async changeCompany(data){
      this.filterData.company_id = data
      await this.getData();
    },
    async changeCustomer(data){
      this.filterData.customer_id = data.id
      await this.getData();
    },
    async changeStatusOption() {
      await this.getData();
    },
    async resetFilter() {
      this.filterData = {}
      await this.getData()
    }
  },
  async mounted(){
    await this.getData()
  }
}
</script>

<style>

</style>